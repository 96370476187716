import React from 'react';
import Header from './components/Header';
import Carousel from './components/Carousel';
import About from './components/About';
import Processing from './components/Processing';
import ColorSelection from './components/ColorSelection';
import Footer from './components/Footer';

const HomePage = () => (
  <div className="flex flex-col min-h-screen bg-background-color text-text-color">
    <Header />
    <Carousel />
    <About />
    <Processing />
    <ColorSelection />
    <Footer />
  </div>
);

export default HomePage;