import React from "react";
import About from "./About";
import Header from "./Header";
import Footer from "./Footer";

const AboutPage = () => (
  <div className="flex flex-col min-h-screen">
    <Header />
    <main className="flex-grow">
      <About />
    </main>
    <Footer />
  </div>
);

export default AboutPage;
