import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Modal from "react-modal";
import Header from "./Header";
import Footer from "./Footer";
import { createGlobalStyle } from 'styled-components';

Modal.setAppElement("#root");

const ModalStyles = createGlobalStyle`
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 0;
    border-radius: 10px;
    width: 80%;
    max-width: 1000px;
    height: 80vh;
    max-height: 80vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .modal-image-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 60px);
    overflow: hidden;
    background-color: #f0f0f0;
  }

  .modal-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  .modal-text {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px;
    text-align: center;
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .modal-alt {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 1em;
  }

  .modal-description {
    font-size: 0.9em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ColorSeriesDetailPage = () => {
  const { id } = useParams();
  const [materials, setMaterials] = useState([]);
  const [pageDescription, setPageDescription] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedAlt, setSelectedAlt] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");

  const colorSeries = [
    "霧烤硬化系列",
    "結晶系列",
    "珍珠硬化系列",
    "美耐系列",
    "霧滑系列",
    "皮革系列",
    "幻象系列",
    "釉晶系列",
  ];

  const seriesIndex = parseInt(id, 10) - 1;
  const series = colorSeries[seriesIndex];
  const filteredMaterials = materials.filter((m) => m.page === series);

  useEffect(() => {
    fetch(`/api/materials`)
      .then((response) => response.json())
      .then((data) => setMaterials(data))
      .catch((error) => console.error("Error fetching materials:", error));
  }, []);

  useEffect(() => {
    fetch(`/api/page-descriptions`)
      .then((response) => response.json())
      .then((data) => {
        const description = data.find(item => item.page === series)?.description;
        setPageDescription(description || "");
      })
      .catch((error) => console.error("獲取頁面描述時出錯：", error));
  }, [series]);

  const openModal = (image, alt, description) => {
    setSelectedImage(image);
    setSelectedAlt(alt);
    setSelectedDescription(description);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
    setSelectedAlt("");
    setSelectedDescription("");
  };

  return (
    <>
      <ModalStyles />
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow">
          <div className="container mx-auto py-24">
            <h1 className="text-4xl font-bold mb-8 text-center">{series}</h1>
            {pageDescription && (
              <p className="text-lg mb-8 whitespace-pre-line">{pageDescription}</p>
            )}
            {filteredMaterials.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {filteredMaterials.map((material) => (
                  <div
                    key={material.id}
                    className="border p-4 rounded-lg shadow-md flex flex-col items-center cursor-pointer"
                    onClick={() => openModal(material.image, material.alt, material.description)}
                  >
                    <img
                      src={`/uploads/${material.image.split('/').pop()}`}
                      alt={material.alt}
                      className="w-full h-auto max-h-[200px] object-cover rounded-lg mb-4"
                    />
                    <p className="text-center">{material.alt}</p>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-lg text-center mt-8">沒有找到相關的材料圖片。</p>
            )}
          </div>
        </main>
        <Footer />
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Material Image"
          className="modal"
          overlayClassName="overlay"
        >
          {selectedImage && (
            <div className="modal-content">
              <button onClick={closeModal} className="close-button">
                &times;
              </button>
              <div className="modal-image-container">
                <img
                  src={`/uploads/${selectedImage.split('/').pop()}`}
                  alt={selectedAlt}
                  className="modal-image"
                />
              </div>
              <div className="modal-text">
                <p className="modal-alt">{selectedAlt}</p>
                <p className="modal-description">{selectedDescription}</p>
              </div>
            </div>
          )}
        </Modal>
      </div>
    </>
  );
};

export default ColorSeriesDetailPage;