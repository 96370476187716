import React from "react";
import Processing from "./Processing";
import Header from "./Header";
import Footer from "./Footer";

const ProcessingPage = () => (
  <div className="flex flex-col min-h-screen">
    <Header />
    <main className="flex-grow">
      <Processing />
    </main>
    <Footer />
  </div>
);

export default ProcessingPage;
