import React from 'react';
import { motion } from 'framer-motion';

const Processing = () => (
  <section className="py-24 bg-white, text-black">
    <div className="container mx-auto px-6">
      <div className="flex flex-wrap items-center">
        <motion.div 
          className="w-full lg:w-1/3 lg:pr-12 mb-8 lg:mb-0"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
                    <h2 className="text-4xl font-bold mb-6">加工工藝</h2>
          <h3 className="text-2xl font-semibold mb-4">Processing ART</h3>
          <p className="text-lg mb-6">
            我們的加工工藝融合了傳統技術和現代創新。每一件廚具都經過精心設計和製作,確保最高品質和耐用性。
          </p>
          <p className="text-lg mb-6">
            從精密的切割到細緻的拋光,我們的工匠們用心雕琢每一個細節,為您打造完美的廚房空間。
          </p>

          <motion.button 
                        className="bg-accent text-white px-6 py-3 rounded-full hover:bg-opacity-80 transition duration-300"

            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            了解更多
          </motion.button>
        </motion.div>
        <motion.div 
          className="w-full lg:w-2/3"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <img src="/images/processing.jpg" alt="Modern Kitchen Design" className="w-full h-auto max-h-[500px] object-cover rounded-lg shadow-lg" />
        </motion.div>
      </div>
    </div>
  </section>
);

export default Processing;