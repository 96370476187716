import React from 'react';
import { FaIdCard, FaPhone, FaFax, FaMapMarkerAlt } from 'react-icons/fa';

const Footer = () => (
  <footer className="bg-secondary-color text-white py-12">
    <div className="container mx-auto px-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div>
          <p className="mb-2 flex items-center">
            <FaIdCard className="mr-2" /><span className="font-semibold">統編:</span> 16655291
          </p>
          <p className="mb-2 flex items-center">
            <FaPhone className="mr-2" /><span className="font-semibold">電話:</span> 04-8752186
          </p>
        </div>
        <div>
          <p className="mb-2 flex items-center">
            <FaFax className="mr-2" /><span className="font-semibold">傳真:</span> 04-8752185
          </p>
          <p className="mb-2 flex items-center">
            <FaMapMarkerAlt className="mr-2" /><span className="font-semibold">地址:</span> 彰化縣田中鎮沙崙里新工三路160號
          </p>
        </div>
      </div>
      <div className="border-t border-gray-700 pt-8 text-center">
        <p className="text-sm">Copyright © 2024 奕發興業有限公司. All rights reserved</p>
      </div>
    </div>
  </footer>
);

export default Footer;