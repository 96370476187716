import React from 'react';
import { motion } from 'framer-motion';

const About = () => (
  <section className="py-24 bg-white, text-black">
    <div className="container mx-auto px-6">
      <div className="flex flex-wrap items-center">
        <motion.div 
          className="w-full lg:w-2/3 mb-8 lg:mb-0"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <img src="/images/about-us.jpg" alt="Modern Kitchen Design" className="w-full h-auto max-h-[500px] object-cover rounded-lg shadow-lg" />
        </motion.div>
        <motion.div 
          className="w-full lg:w-1/3 lg:pl-12"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
                    <h2 className="text-4xl font-bold mb-6">關於我們</h2>
          <h3 className="text-2xl font-semibold mb-4">About</h3>
          <p className="text-lg mb-6">
            宜發國際股份有限公司成立於1990年，專注於高品質廚具的設計和製造。我們的使命是為客戶提供最優質、最實用的廚房解決方案。
          </p>
          <p className="text-lg mb-6">
            憑藉30多年的行業經驗，我們不斷創新，將最新的技術和設計理念融入每一件產品中。
          </p>

          <motion.button 
                        className="bg-accent text-white px-6 py-3 rounded-full hover:bg-opacity-80 transition duration-300"

            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            了解更多
          </motion.button>
        </motion.div>
      </div>
    </div>
  </section>
);
          
export default About;