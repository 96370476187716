import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaChevronDown, FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

const colorSeries = [
  "霧烤硬化系列",
  "結晶系列",
  "珍珠硬化系列",
  "美耐系列",
  "霧滑系列",
  "皮革系列",
  "幻象系列",
  "釉晶系列",
];

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleColorSeriesClick = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const closeDropdown = (e) => {
      if (!e.target.closest('.color-series-dropdown')) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', closeDropdown);
    return () => document.removeEventListener('click', closeDropdown);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const menuItems = ["首頁", "關於奕發", "加工工藝", "嚴選色系"];

  return (
    <header
      className={`fixed w-full z-10 transition-colors duration-300 ${
        isScrolled ? "bg-black bg-opacity-80" : "bg-gray-800 bg-opacity-50"
      }`}
    >
      <div className="container mx-auto flex justify-between items-center py-4 px-6">
        <div className="text-white text-xl md:text-2xl font-bold font-serif">
          奕發門板
        </div>
        <nav className="hidden md:block">
          <ul className="flex space-x-4 lg:space-x-8 items-center">
            {menuItems.map((item, index) => (
              <li key={item} className="relative">
                {index === 3 ? (
                  <div className="color-series-dropdown relative">
                    <button
                      onClick={handleColorSeriesClick}
                      className="text-white hover:text-gray-300 cursor-pointer transition-colors duration-300 font-medium text-xs lg:text-sm uppercase tracking-wider flex items-center p-2"
                    >
                      {item}
                      <FaChevronDown
                        className={`ml-1 transform transition-transform duration-300 ${
                          isOpen ? "rotate-180" : ""
                        }`}
                      />
                    </button>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          transition={{ duration: 0.2 }}
                          className="absolute left-0 w-48 lg:w-56 mt-2 rounded-lg shadow-lg overflow-hidden z-10"
                        >
                          <div className="backdrop-blur-md bg-gray-800 bg-opacity-90 rounded-lg">
                            <ul className="py-2">
                              {colorSeries.map((series, seriesIndex) => (
                                <li
                                  key={series}
                                  className="px-4 py-2 hover:bg-gray-700 transition duration-300"
                                >
                                  <Link 
                                    to={`/color-series/${seriesIndex + 1}`}
                                    className="block text-white text-xs lg:text-sm font-medium"
                                  >
                                    {series}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                ) : (
                  <Link
                    to={
                      index === 0 ? "/" : index === 1 ? "/about" : "/processing"
                    }
                    className="text-white hover:text-gray-300 cursor-pointer transition-colors duration-300 font-medium text-xs lg:text-sm uppercase tracking-wider p-2"
                  >
                    {item}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </nav>
        <button
          className="md:hidden text-white"
          onClick={toggleMobileMenu}
        >
          {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
      </div>
      {/* Mobile Menu */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className="md:hidden bg-gray-800 bg-opacity-95"
          >
            <ul className="py-4">
              {menuItems.map((item, index) => (
                <li key={item} className="px-6 py-2">
                  {index === 3 ? (
                    <div>
                      <button
                        onClick={handleColorSeriesClick}
                        className="text-white hover:text-gray-300 cursor-pointer transition-colors duration-300 font-medium text-sm uppercase tracking-wider flex items-center"
                      >
                        {item}
                        <FaChevronDown
                          className={`ml-1 transform transition-transform duration-300 ${
                            isOpen ? "rotate-180" : ""
                          }`}
                        />
                      </button>
                      {isOpen && (
                        <ul className="pl-4 mt-2">
                          {colorSeries.map((series, seriesIndex) => (
                            <li key={series} className="py-1">
                              <Link 
                                to={`/color-series/${seriesIndex + 1}`}
                                className="block text-white text-sm"
                              >
                                {series}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ) : (
                    <Link
                      to={
                        index === 0 ? "/" : index === 1 ? "/about" : "/processing"
                      }
                      className="text-white hover:text-gray-300 cursor-pointer transition-colors duration-300 font-medium text-sm uppercase tracking-wider"
                    >
                      {item}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Header;