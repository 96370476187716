import React, { useState, useEffect } from "react";
import { FaTrash, FaSpinner } from "react-icons/fa";

const AdminPanel = () => {
  const [slides, setSlides] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [newSlide, setNewSlide] = useState({ alt: "" });
  const [newMaterial, setNewMaterial] = useState({
    alt: "",
    page: "",
    description: "",
    alts: [],
    descriptions: [],
  });
  const [pageDescriptions, setPageDescriptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [confirmDelete, setConfirmDelete] = useState({
    show: false,
    id: null,
    type: "",
  });
  const [files, setFiles] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [expandedPages, setExpandedPages] = useState({});
  const [lastAddedId, setLastAddedId] = useState(null);
  const [editingMaterial, setEditingMaterial] = useState(null);

  const pages = [
    "霧烤硬化系列",
    "結晶系列",
    "珍珠硬化系列",
    "美耐系列",
    "霧滑系列",
    "皮革系列",
    "幻象系列",
    "釉晶系列",
  ];

  const fetchSlides = () => {
    setLoading(true);
    fetch(`/api/slides`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setSlides(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching slides:", error);
        setLoading(false);
      });
  };

  const fetchMaterials = () => {
    setLoading(true);
    fetch(`/api/materials`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setMaterials(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching materials:", error);
        setLoading(false);
      });
  };

  const fetchPageDescriptions = () => {
    fetch(`/api/page-descriptions`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        const descriptions = {};
        data.forEach((item) => {
          descriptions[item.page] = item.description;
        });
        setPageDescriptions(descriptions);
      })
      .catch((error) => console.error("Error fetching page descriptions:", error));
  };

  useEffect(() => {
    fetchSlides();
    fetchMaterials();
    fetchPageDescriptions();
    setExpandedPages({ [pages[0]]: true });
  }, []);

  const handleSlideSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    fetch(`/api/slides`, {
      method: "POST",
      body: formData,
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        fetchSlides();
        setNewSlide({ alt: "" });
        setMessage("輪播圖添加成功！");
        setLastAddedId(data.id);
        setTimeout(() => setLastAddedId(null), 3000); // 3秒後取消高亮
      })
      .catch(() => setMessage("輪播圖添加失敗！"));
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles(newFiles);
    setNewMaterial({
      ...newMaterial,
      alts: newFiles.map(() => ""),
      descriptions: newFiles.map(() => "")
    });
  };

  const handleAltChange = (index, value) => {
    const newAlts = [...newMaterial.alts];
    newAlts[index] = value;
    setNewMaterial({ ...newMaterial, alts: newAlts });
  };

  const handleDescriptionChange = (index, value) => {
    const newDescriptions = [...newMaterial.descriptions];
    newDescriptions[index] = value;
    setNewMaterial({ ...newMaterial, descriptions: newDescriptions });
  };

  const handleMaterialSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append("images", file);
    });
    formData.append("alts", JSON.stringify(newMaterial.alts));
    formData.append("page", newMaterial.page);
    formData.append("descriptions", JSON.stringify(newMaterial.descriptions));

    fetch(`/api/materials`, {
      method: "POST",
      body: formData,
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then(err => { throw err; });
        }
        return response.json();
      })
      .then((data) => {
        fetchMaterials();
        setNewMaterial({ alts: [], page: "", descriptions: [] });
        setFiles([]);
        setMessage("材料圖片添加成功！");
        setLastAddedId(data.id);
        setTimeout(() => setLastAddedId(null), 3000); // 3秒後取消高亮
      })
      .catch((error) => {
        console.error('Error:', error);
        setMessage(error.error || "材料圖片添加失敗！");
      });
  };

  const handlePageDescriptionSubmit = (e, page) => {
    e.preventDefault();
    const description = pageDescriptions[page];
    fetch(`/api/page-descriptions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ page, description }),
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(() => {
        setMessage("頁面說明更新成功！");
      })
      .catch(() => setMessage("頁面說明更新失敗！"));
  };

  const handleDelete = () => {
    setLoading(true);
    fetch(`/api/${confirmDelete.type}s/${confirmDelete.id}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(() => {
        if (confirmDelete.type === "slide") {
          fetchSlides();
        } else if (confirmDelete.type === "material") {
          fetchMaterials();
        }
        setConfirmDelete({ show: false, id: null, type: "" });
        setMessage(`${confirmDelete.type === "slide" ? "輪播圖" : "材料圖片"}刪除成功！`);
      })
      .catch((error) => {
        console.error(`Error deleting ${confirmDelete.type}:`, error);
        setMessage(`刪除失敗：${error.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = (material) => {
    setEditingMaterial(material);
  };

  const handleUpdateMaterial = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(e.target);
    fetch(`/api/materials/${editingMaterial.id}`, {
      method: "PUT",
      body: formData,
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(() => {
        fetchMaterials();
        setEditingMaterial(null);
        setMessage("材料圖片更新成功！");
      })
      .catch((error) => {
        console.error("Error updating material:", error);
        setMessage(`更新失敗：${error.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const toggleDescription = (index) => {
    setExpandedDescriptions(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const togglePageExpand = (page) => {
    setExpandedPages(prev => ({
      ...prev,
      [page]: !prev[page]
    }));
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-8 text-center">後台管理系統</h1>
      {message && (
        <div className="fixed top-0 left-0 right-0 bg-blue-500 text-white px-4 py-3 text-center transition-all duration-300 ease-in-out z-50" style={{transform: message ? 'translateY(0)' : 'translateY(-100%)'}}>
          <span className="block sm:inline">{message}</span>
        </div>
      )}
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <h2 className="text-2xl font-semibold mb-2">輪播圖管理</h2>
          <form onSubmit={handleSlideSubmit} className="mb-4">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="image">
                選擇圖片
              </label>
              <input type="file" name="image" required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="alt">
                圖片描述
              </label>
              <input
                type="text"
                value={newSlide.alt}
                onChange={(e) => setNewSlide({ ...newSlide, alt: e.target.value })}
                placeholder="圖片描述"
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              添加新輪播圖
            </button>
          </form>
          {loading ? (
            <div className="flex justify-center">
              <FaSpinner className="animate-spin text-4xl text-blue-500" />
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {slides.map((slide) => (
                <div key={slide.id} className={`border p-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg ${slide.id === lastAddedId ? 'bg-yellow-100' : ''}`}>
                  <img
                    src={`/uploads/${slide.image.split('/').pop()}`}
                    alt={slide.alt}
                    className="mb-2 w-full h-48 object-cover rounded-lg"
                  />
                  <p className="text-gray-700 text-base">{slide.alt}</p>
                  <button
                    onClick={() =>
                      setConfirmDelete({ show: true, id: slide.id, type: "slide" })
                    }
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-2 flex items-center justify-center transition duration-300 ease-in-out"
                  >
                    <FaTrash className="mr-2" /> 刪除
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h2 className="text-2xl font-semibold mb-4">材料圖片管理</h2>
        <form onSubmit={handleMaterialSubmit} className="mb-8">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="images">
              選擇圖片（可多選）
            </label>
            <input type="file" name="images" multiple required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={handleFileChange} />
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mb-4">
            {files.map((file, index) => (
              <div key={index} className="border rounded-lg p-2">
                <img src={URL.createObjectURL(file)} alt="預覽" className="w-full h-32 object-cover mb-2 rounded" />
                <input
                  type="text"
                  value={newMaterial.alts[index] || ""}
                  onChange={(e) => handleAltChange(index, e.target.value)}
                  placeholder="圖片描述"
                  className="w-full mb-2 p-1 border rounded"
                />
                <button
                  type="button"
                  onClick={() => toggleDescription(index)}
                  className="w-full bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-1 px-2 rounded"
                >
                  {expandedDescriptions[index] ? '收起描述' : '展開描述'}
                </button>
                {expandedDescriptions[index] && (
                  <textarea
                    value={newMaterial.descriptions[index] || ""}
                    onChange={(e) => handleDescriptionChange(index, e.target.value)}
                    placeholder="材料描述"
                    className="w-full mt-2 p-1 border rounded"
                  />
                )}
              </div>
            ))}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="page">
              選擇頁面
            </label>
            <select
              value={newMaterial.page}
              onChange={(e) => setNewMaterial({ ...newMaterial, page: e.target.value })}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="">選擇頁面</option>
              {pages.map((page) => (
                <option key={page} value={page}>
                  {page}
                </option>
              ))}
            </select>
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            添加新材料圖片
          </button>
        </form>
      </div>

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h2 className="text-2xl font-semibold mb-4">頁面說明和材料管理</h2>
        {pages.map((page, index) => (
          <div key={page} className="mb-4">
            <button
              onClick={() => togglePageExpand(page)}
              className="w-full bg-gray-200 hover:bg-gray-300 text-left text-gray-800 font-semibold py-2 px-4 rounded flex justify-between items-center"
            >
              <span>{page}</span>
              <span className="text-sm">
                ({materials.filter((m) => m.page === page).length} 個材料)
              </span>
            </button>
            {expandedPages[page] && (
              <div className="mt-4">
                <form
                  onSubmit={(e) => handlePageDescriptionSubmit(e, page)}
                  className="mb-4"
                >
                  <textarea
                    value={pageDescriptions[page] || ""}
                    onChange={(e) =>
                      setPageDescriptions({
                        ...pageDescriptions,
                        [page]: e.target.value,
                      })
                    }
                    placeholder="頁面說明"
                    required
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
                  />
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    更新頁面說明
                  </button>
                </form>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {materials
                    .filter((material) => material.page === page)
                    .map((material) => (
                      <div
                        key={material.id}
                        className={`border p-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg ${material.id === lastAddedId ? 'bg-yellow-100' : ''}`}
                      >
                        {editingMaterial && editingMaterial.id === material.id ? (
                          <form onSubmit={handleUpdateMaterial}>
                            <input
                              type="text"
                              name="alt"
                              defaultValue={material.alt}
                              className="mb-2 w-full p-2 border rounded"
                            />
                            <textarea
                              name="description"
                              defaultValue={material.description}
                              className="mb-2 w-full p-2 border rounded"
                            />
                            <button
                              type="submit"
                              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                            >
                              保存
                            </button>
                            <button
                              type="button"
                              onClick={() => setEditingMaterial(null)}
                              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                            >
                              取消
                            </button>
                          </form>
                        ) : (
                          <>
                            <img
                              src={`/uploads/${material.image.split('/').pop()}`}
                              alt={material.alt}
                              className="mb-2 w-full h-48 object-cover rounded-lg"
                            />
                            <p className="text-gray-700 text-base">{material.alt}</p>
                            <div className="flex justify-between mt-2">
                              <button
                                onClick={() => handleEdit(material)}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center transition duration-300 ease-in-out"
                              >
                                編輯
                              </button>
                              <button
                                onClick={() =>
                                  setConfirmDelete({
                                    show: true,
                                    id: material.id,
                                    type: "material",
                                  })
                                }
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center transition duration-300 ease-in-out"
                              >
                                <FaTrash className="mr-2" /> 刪除
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      {confirmDelete.show && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg shadow-xl">
            <h3 className="text-lg font-bold mb-4">確認刪除</h3>
            <p>您確定要刪除這個項目嗎？</p>
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => setConfirmDelete({ show: false, id: null, type: "" })}
                className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded mr-2"
              >
                取消
              </button>
              <button
                onClick={handleDelete}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              >
                確認刪除
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPanel;