import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./HomePage";
import ProcessingPage from "./components/ProcessingPage";
import AdminPanel from "./components/AdminPanel";
import ColorSeriesPage from "./components/ColorSeriesPage";
import ColorSeriesDetailPage from "./components/ColorSeriesDetailPage";
import AboutPage from "./components/AboutPage"; // 確保導入 AboutPage 組件

const App = () => {
  return (
    <Router>
      <div className="bg-background-color min-h-screen">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/processing" element={<ProcessingPage />} />
          <Route path="/admin" element={<AdminPanel />} />
          <Route path="/color-series" element={<ColorSeriesPage />} />
          <Route path="/color-series/:id" element={<ColorSeriesDetailPage />} />
          <Route path="/about" element={<AboutPage />} />{" "}
          {/* 使用 AboutPage 組件 */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
