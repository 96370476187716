import React from "react";
import { Link } from "react-router-dom";

const ColorSeriesPage = () => {
  const colorSeries = [
    "霧烤硬化系列",
    "結晶系列",
    "珍珠硬化系列",
    "美耐系列",
    "霧滑系列",
    "皮革系列",
    "幻象系列",
    "釉晶系列",
  ];

  return (
    <div className="container mx-auto py-24">
      <h1 className="text-4xl font-bold mb-8 text-center">嚴選色系</h1>
      <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {colorSeries.map((series, index) => (
          <li
            key={index}
            className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
          >
            <Link
              to={`/color-series/${index + 1}`} // 確保 id 從 1 開始
              className="text-xl font-semibold text-gray-800 hover:text-gray-600"
            >
              {series}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ColorSeriesPage;
