import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaChevronDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const ColorSelection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const colorSeries = [
    "霧烤硬化系列",
    "結晶系列",
    "珍珠硬化系列",
    "美耐系列",
    "霧滑系列",
    "皮革系列",
    "幻象系列",
    "釉晶系列",
  ];

  const handleSeriesClick = (index) => {
    navigate(`/color-series/${index + 1}`); // 確保 id 從 1 開始
    setIsOpen(false);
  };

  return (
    <section className="py-24 bg-white text-black">
      <div className="container mx-auto px-6">
        <div className="flex flex-wrap items-center">
          <motion.div
            className="w-full lg:w-2/3 mb-8 lg:mb-0"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <img
              src="/images/color-selection.jpg"
              alt="Modern Kitchen Design"
              className="w-full h-auto max-h-[500px] object-cover rounded-lg shadow-lg"
            />
          </motion.div>
          <motion.div
            className="w-full lg:w-1/3 lg:pl-12"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <h2 className="text-4xl font-bold mb-6">嚴選色系</h2>
            <h3 className="text-2xl font-semibold mb-4">Color Selection</h3>
            <p className="text-lg mb-6">
              我們精心挑選的色系能夠滿足各種風格和喜好。從經典的中性色調到大膽的現代色彩,我們的色系選擇豐富多樣。
            </p>
            <p className="text-lg mb-6">
              每一種顏色都經過專業設計師的精心搭配,確保能夠為您的廚房帶來獨特的美感和個性。
            </p>

            <div className="relative mb-8">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="w-full bg-gray-100 text-left px-4 py-3 rounded-lg shadow-md flex justify-between items-center hover:bg-gray-200 transition duration-300"
              >
                <span className="font-medium">選擇色系</span>
                <FaChevronDown
                  className={`transform transition-transform duration-300 ${
                    isOpen ? "rotate-180" : ""
                  }`}
                />
              </button>
              <AnimatePresence>
                {isOpen && (
                  <motion.ul
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                    className="absolute w-full bg-white mt-2 rounded-lg shadow-lg z-10"
                  >
                    {colorSeries.map((series, index) => (
                      <motion.li
                        key={series}
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.1, delay: index * 0.05 }}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer transition duration-300"
                        onClick={() => handleSeriesClick(index)}
                      >
                        {series}
                      </motion.li>
                    ))}
                  </motion.ul>
                )}
              </AnimatePresence>
            </div>
            <motion.button
              className="bg-accent text-white px-6 py-3 rounded-full hover:bg-opacity-80 transition duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              了解更多
            </motion.button>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default ColorSelection;
